export class Option {
  constructor(
    public id: number,
    public name: string,
    public group_id: number,
    public price: number,
    public quantity?: number,
    public is_active?: boolean,
    public modifies_price?: boolean,
    public requires_age_check?: boolean
  ) { }
}
