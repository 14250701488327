import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AppDateAdapter, APP_DATE_FORMATS } from './date.adapter';
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material";
import { CompanyService } from 'src/app/services/company/company.service';
import { Company } from 'src/app/models/Company';
import { ReportService } from 'src/app/services/report/report.service';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
  providers: [
    {
      provide: DateAdapter, useClass: AppDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
  ]
})
export class ReportsComponent implements OnInit {

  sDateSince: string = '';
  sDateUntil: string = '';
  sDateSinceFormat: string = '';
  sDateUntilFormat: string = '';
  lCompanies: Company[] = [];
  nTicketAverage: number = 0;
  sBestSeller: string = '';
  sHotZone: string = '';
  sHotHour: string = '';
  dtTodayDate: Date;
  bShowSpinner: boolean = false;

  /**
   * Constructor
   * @param oDatePipe
   * @param oCompanyServices
   * @param oReportServices
   * @param oSnackBar
   */
  constructor(
    private oDatePipe: DatePipe,
    private oCompanyServices: CompanyService,
    private oReportServices: ReportService,
    private oSnackBar: MatSnackBar
  ) {
    this.dtTodayDate = new Date();
  }

  /**
   * ngOnInit
   */
  ngOnInit() {
    this.get_companies()
    this.get_reports(true)
  }

  /**
   * Funcion encargada de traer los resportes
   * @param bIsFirst
   */
  get_reports(bIsFirst) {
    this.bShowSpinner = true
    this.oReportServices.get_reports(this.sDateSinceFormat, this.sDateUntilFormat, this.get_companies_checked(), bIsFirst).subscribe(
      oResult => {
        this.nTicketAverage = null != oResult.average_ticket.average ? oResult.average_ticket.average : 0
        this.sBestSeller = null != oResult.best_seller ? oResult.best_seller.name : ''
        this.sHotZone = null != oResult.hot_zone ? oResult.hot_zone.area : ''
        this.sHotHour = ''
        if (oResult.hot_hour.length > 0) {
          this.sHotHour = oResult.hot_hour[0] + ' hs'
          if (undefined != oResult.hot_hour[1]) {
            this.sHotHour += ' y ' + oResult.hot_hour[1] + ' hs'
          }
        }
        this.bShowSpinner = false
      },
      oError => {
        this.bShowSpinner = false
        this.open_alert("Error, " + oError.body);
      }
    )
  }

  /**
   * Funcion encargada de descargar el excel
   */
  get_excel() {
    this.bShowSpinner = true
    this.oReportServices.get_excel(this.sDateSinceFormat, this.sDateUntilFormat, this.get_companies_checked()).subscribe(
      oResult => {
        var download_excel = <HTMLLinkElement>document.getElementById('download_excel');
        download_excel.href = `data:application/octet-stream;base64,${oResult.file}`;
        download_excel.click();
        this.bShowSpinner = false
      },
      oError => {
        this.bShowSpinner = false
        this.open_alert("Error, " + oError.body);
      }
    )
  }

  /**
   * Funcion relacionada al boton search
   */
  search() {
    this.get_reports(false)
  }

  /**
   * Funcion que dada una company clickeada en el listado, la pone como chequeda true o false segun corresponda
   * @param oCompany
   */
  apply_filter_company(oCompany) {
    oCompany.bChecked ? oCompany.bChecked = false : oCompany.bChecked = true
    this.get_reports(false)
  }

  /**
   * Funcion que devuelve las empresas que estan seleccionadas en el filtro de la izquierda
   */
  get_companies_checked() {
    let lCompaniesFilter = []
    for (let oCompany of this.lCompanies) {
      if (oCompany.bChecked) {
        lCompaniesFilter.push(oCompany.id)
      }
    }
    return lCompaniesFilter
  }

  /**
   * Funcion encargada de llamar al controlador de servicio para obtener las empresas(restaurantes)
   */
  get_companies() {
    this.oCompanyServices.get_companies().subscribe(
      oResult => {
        if (this.lCompanies.length > 0) {
          for (let i = 0; i < oResult.length; i++) {
            oResult[i].bChecked = this.lCompanies[i].bChecked
          }
          this.lCompanies = oResult
        }
        else {
          this.lCompanies = oResult
          for (let oCompany of this.lCompanies) {
            oCompany.bChecked = true
          }
        }
      },
      oError => {

      }
    )
  }

  /**
   * Funcion que cambia el formato de fecha dd/mm/yyyy a yyyy-mm-dd
   */
  format_since() {
    this.sDateSinceFormat = this.oDatePipe.transform(this.sDateSince, 'yyyy-MM-dd');
  }

  /**
   * Funcion que cambia el formato de fecha dd/mm/yyyy a yyyy-mm-dd
   */
  format_until() {
    this.sDateUntilFormat = this.oDatePipe.transform(this.sDateUntil, 'yyyy-MM-dd');
  }

  /**
   * Mostrar notificacion dado un mensaje
   * @param sMsg
   */
  open_alert(sMsg) {
    this.oSnackBar.open(sMsg, '', {
      duration: 2000
    });
  }

}
