import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators'
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Category } from 'src/app/models/Category';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(
    private oHttpClient: HttpClient
  ) { }

  /**
   * Funcion para obtener las categorias
   * @param nPage
   * @param sFilter
   */
  get_categories(nPage, sFilter, nCompanyId) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.getToken()
      })
    };
    return this.oHttpClient.post<any>(environment.sUrlAPI + 'categories/' + nPage, { filter: sFilter, company_id: nCompanyId }, httpOptions).pipe(
      catchError(this.handleError)
    )
  }

  /**
   * Funcion para obtener las categorias sin paginado ni filtro
   * @param oCategory
   */
  get_all_categories(nCompanyId) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.getToken()
      })
    };
    return this.oHttpClient.get<Category[]>(environment.sUrlAPI + 'categories/' + nCompanyId, httpOptions).pipe(
      catchError(this.handleError)
    )
  }
  /**
   * Funcion para crear o editar una cateogria dependiendo si el parametro tiene id
   * @param oCategory
   */
  save_category(oCategory) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.getToken()
      })
    };
    if (null == oCategory.id) {
      return this.oHttpClient.post<any>(environment.sUrlAPI + 'categories', { category: oCategory }, httpOptions).pipe(
        catchError(this.handleError)
      )
    }
    else {
      return this.oHttpClient.put<any>(environment.sUrlAPI + 'categories/' + oCategory.id, { category: oCategory }, httpOptions).pipe(
        catchError(this.handleError)
      )
    }
  }

  /**
   * Funcion para eliminar una cateogria dado un id
   * @param nCategoryId
   */
  delete_category(nCategoryId) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.getToken()
      })
    };
    return this.oHttpClient.delete<any>(environment.sUrlAPI + 'categories/' + nCategoryId, httpOptions).pipe(
      catchError(this.handleError)
    )
  }

  /**
   * Devuelve el token en caso de tener un usuario logueado(almacenado en local storage)
   */
  private getToken() {
    if (localStorage.getItem("mafalda_web_session") && localStorage.getItem("mafalda_web_session") != '') {
      let Session = JSON.parse(localStorage.getItem("mafalda_web_session"));
      return Session.token;
    }
    return '';
  }

  /**
   *
   */
  private handleError(error: HttpErrorResponse) {
    return throwError(
      {
        code: error.status,
        body: error.error
      }
    );
  }

}
