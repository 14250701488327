import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpErrorResponse, HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private oRouter: Router) { }
  intercept(oHttpRequest: HttpRequest<any>, oNext: HttpHandler): Observable<HttpEvent<any>> {
    const oSession = JSON.parse(localStorage.getItem("mafalda_web_session"));
    let sToken = "";
    if (oSession && oSession.token) {
      sToken = oSession.token;
    }

    if (!sToken) {
      return oNext.handle(oHttpRequest);
    }

    const oHeaders = oHttpRequest.clone({
      setHeaders: {
        'Authorization': sToken,
      }
    });

    return oNext.handle(oHeaders).pipe(
      catchError((oError: HttpErrorResponse) => {
        if (oError && oError.status && oError.status === 401) {
          this.oRouter.navigate(['login']);
        }
        return throwError(oError);
      })
    );
  }
}
