import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { UserService } from '../services/user/user.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SessionGuard implements CanActivate {

	constructor(private authService: UserService, private router: Router) { }

  canActivate() {
    if (this.authService.isLogged()) {
      this.router.navigate(['login']);
      return false;
    }else{
      return true;
    }

  }
}
