import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators'
import { Product } from 'src/app/models/Product';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(
    private oHttpClient: HttpClient
  ) { }

  /**
   * Funcion para obtener un listado de productos
   * @param nPage
   * @param sFilter
   */
  get_products(nPage, sFilter, nCompanyId) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.getToken()
      })
    };
    return this.oHttpClient.post<any>(environment.sUrlAPI + 'products/all/' + nPage, { filter: sFilter, company_id: nCompanyId }, httpOptions).pipe(
      catchError(this.handleError)
    )
  }

  /**
   * Fuincion para obtener un producto dado un id
   * @param nProductId
   */
  get_product(nProductId) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.getToken()
      })
    };
    return this.oHttpClient.get<Product>(environment.sUrlAPI + 'products/get/' + nProductId, httpOptions).pipe(
      catchError(this.handleError)
    )
  }

  /**
   * Funcion para agregar o editar un producto dependiendo si el parametro tiene id
   * @param oProduct
   */
  save_product(oProduct) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.getToken()
      })
    }
    if (null == oProduct.id) {
      return this.oHttpClient.post<any>(environment.sUrlAPI + 'products', oProduct, httpOptions).pipe(
        catchError(this.handleError)
      )
    }
    else {
      return this.oHttpClient.put<any>(environment.sUrlAPI + 'products/' + oProduct.id, oProduct, httpOptions).pipe(
        catchError(this.handleError)
      )
    }
  }

  /**
   * Funcion para eliminar un producto
   * @param nProductId
   */
  delete_product(nProductId) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.getToken()
      })
    }
    return this.oHttpClient.delete<any>(environment.sUrlAPI + 'products/' + nProductId, httpOptions).pipe(
      catchError(this.handleError)
    )
  }

  /**
   * Devuelve el token en caso de tener un usuario logueado(almacenado en local storage)
   */
  private getToken() {
    if (localStorage.getItem("mafalda_web_session") && localStorage.getItem("mafalda_web_session") != '') {
      let Session = JSON.parse(localStorage.getItem("mafalda_web_session"));
      return Session.token;
    }
    return '';
  }

  uploadImage(sImg, sName) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.getToken()
      })
    }
    return this.oHttpClient.post<any>(environment.sUrlAPI + 'products/image', { image: sImg, sImageName: sName }, httpOptions).pipe(
      catchError(this.handleError)
    )
  }

  /**
   *
   */
  private handleError(error: HttpErrorResponse) {
    return throwError(
      {
        code: error.status,
        body: error.error
      }
    );
  }
}
