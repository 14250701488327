import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(
    private oHttpClient: HttpClient
  ) { }

  /**
   * Funcion encargada de traerse los reportes
   */
  get_reports(sDateSince, sDateUntil, lCompanies, bIsFirst) {
    const lHttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.getToken()
      })
    };
    return this.oHttpClient.post<any>(environment.sUrlAPI + 'reports', {since: sDateSince, until: sDateUntil, companies: lCompanies, first: bIsFirst} ,lHttpOptions).pipe(catchError(this.handleError))
  }

  /**
   * Funcion encargada de descargar el excel
   */
  get_excel(sDateSince, sDateUntil, lCompanies){
    const lHttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.getToken()
      })
    };
    return this.oHttpClient.post<any>(environment.sUrlAPI + 'reports/download_excel', {since: sDateSince, until: sDateUntil, companies: lCompanies} ,lHttpOptions).pipe(catchError(this.handleError))
  }

  /**
  * Devuelve el token en caso de tener un usuario logueado(almacenado en local storage)
  */
  private getToken() {
    if (localStorage.getItem("mafalda_web_session") && localStorage.getItem("mafalda_web_session") != '') {
      let Session = JSON.parse(localStorage.getItem("mafalda_web_session"));
      return Session.token;
    }
    return '';
  }


  /**
   * 
   */
  private handleError(error: HttpErrorResponse) {
    return throwError(
      {
        code: error.status,
        body: error.error
      }
    );
  }
}
