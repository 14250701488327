import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  sUsername: string = '';
  sPassword: string = '';
  sErrorRequest: string = '';

  constructor(
    private oUserService: UserService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  login(){
      this.oUserService.login(this.sUsername, this.sPassword).subscribe(
        oResult => {
          localStorage.setItem("mafalda_web_session", JSON.stringify(oResult));
          this.router.navigate(['/reports']);
          this.sUsername = ''
          this.sPassword = ''
          this.sErrorRequest = ''
        },
        oError => {
          this.sErrorRequest = "Email o contraseña incorrectos";
        }
      )
  }

}
