import { Group } from './Group';

export class Product {
  constructor(
    public id: number,
    public name: string,
    public description: string,
    public amount: number,
    public category_id: number,
    public is_active: number,
    public groups: Group[],
    public counter_product: boolean,
    public image: string
  ) { }
}
