import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { SessionGuard } from './guard/session.guard';
import { ListCategoriesComponent } from './pages/categories/list-categories/list-categories.component';
import { ListProductsComponent } from './pages/products/list-products/list-products.component';
import { NeweditProductComponent } from './pages/products/newedit-product/newedit-product.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'reports', component: ReportsComponent, canActivate: [SessionGuard] },
  {
    path: 'categories',
    canActivate: [SessionGuard],
    children: [
      { path: '', component: ListCategoriesComponent },
      { path: '**', component: ListCategoriesComponent }
    ]
  },
  {
    path: 'products',
    canActivate: [SessionGuard],
    children: [
      { path: '', component: ListProductsComponent },
      { path: 'create', component: NeweditProductComponent },
      { path: ':nProductId/:nCompanyId', component: NeweditProductComponent },
      { path: '**', component: ListProductsComponent }
    ]
  },
  { path: '**', component: ReportsComponent, canActivate: [SessionGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
