import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Session } from 'src/app/models/Session';
import { environment } from 'src/environments/environment';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private oHttpClient: HttpClient
  ) { }

  /**
   * Dado los parametros hace la solicitud de inicio de sesion
   */
  login(sUser: string, sPassword: string) {
    const lHttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.oHttpClient.post<Session>(environment.sUrlAPI + 'login', { "username": sUser, "password": sPassword, "system": true }, lHttpOptions).pipe(
      catchError(this.handleError)
    )
  }

  /**
   * Funcion para cerrar sesion
   */
  logout() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.getToken()
      })
    };
    return this.oHttpClient.post<Session>(environment.sUrlAPI + 'users/logout', { "token": this.getToken() }, httpOptions).pipe(
      catchError(this.handleError)
    )
  }

  /**
  * Devuelve el token en caso de tener un usuario logueado(almacenado en local storage)
  */
  private getToken() {
    if (localStorage.getItem("mafalda_web_session") && localStorage.getItem("mafalda_web_session") != '') {
      let Session = JSON.parse(localStorage.getItem("mafalda_web_session"));
      return Session.token;
    }
    return '';
  }

  /**
   * Funcion que retorna true o false si hay un usuario logueado almacenado en el local storage
   */
  isLogged() {
    return localStorage.getItem('mafalda_web_session') == null;
  }

  /**
   * 
   */
  get_logged_name() {
    if (localStorage.getItem("mafalda_web_session") && localStorage.getItem("mafalda_web_session") != '') {
      let Session = JSON.parse(localStorage.getItem("mafalda_web_session"));
      return Session.name;
    }
    return '';
  }

  /**
   * 
   */
  private handleError(error: HttpErrorResponse) {
    return throwError(
      {
        code: error.status,
        body: error.error
      }
    );
  }
}
