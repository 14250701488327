import { Component, OnInit } from '@angular/core';
import { Group } from 'src/app/models/Group';
import { Option } from 'src/app/models/Option';
import { Product } from 'src/app/models/Product';
import { ProductService } from 'src/app/services/product/product.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryService } from 'src/app/services/category/category.service';
import { Category } from 'src/app/models/Category';
import { MatSnackBar } from '@angular/material';
import { CompanyService } from 'src/app/services/company/company.service';
import { Company } from 'src/app/models/Company';

@Component({
  selector: 'app-newedit-product',
  templateUrl: './newedit-product.component.html',
  styleUrls: ['./newedit-product.component.scss']
})
export class NeweditProductComponent implements OnInit {

  lGroups: Group[] = [];
  oProduct: Product = new Product(null, '', '', null, 0, 1, this.lGroups, false, null);
  sGroupName: string = '';
  sOptionName: string = '';
  nOptionPrice: number = 0;
  nIndex: number = null;
  oSubscribe: any;
  lCategories: Category[] = [];
  bGroupError: boolean = false;
  bOptionError: boolean = false;
  bShowSpinner: boolean = false;
  aCompanies: Company[] = [];
  nCompanyId: number = null;
  nCompanyEdit: number = null;
  bEdit: boolean = false;

  /**
   * Constructor
   * @param oProductService
   * @param oCategoryService
   * @param route
   * @param router
   * @param oSnackBar
   */
  constructor(
    private oProductService: ProductService,
    private oCategoryService: CategoryService,
    private route: ActivatedRoute,
    private router: Router,
    private oSnackBar: MatSnackBar,
    private oCompanyServices: CompanyService
  ) { }

  /**
   * NgOnInit
   */
  ngOnInit() {
    this.oSubscribe = this.route.params.subscribe(
      params => {
        if (params['nProductId'] && params['nCompanyId']) {
          this.bEdit = true;
          this.get_companies(true, params['nCompanyId'], this.bEdit)
          this.get_product(params['nProductId'])
        }
        else {
          this.get_companies(true)
        }
      }
    );
  }

  /**
   * NgOnDestroy
   */
  ngOnDestroy() {
    this.oSubscribe.unsubscribe();
  }

  /**
 * Funcion encargada de llamar al controlador de servicio para obtener las empresas(restaurantes)
 */
  get_companies(bShowSpinner, nCompany?, bEdit?) {
    if (bShowSpinner) {
      this.bShowSpinner = true
    }
    this.oCompanyServices.get_companies().subscribe(
      oResult => {
        this.aCompanies = oResult;
        this.nCompanyId = nCompany ? +nCompany : (this.aCompanies.length > 0 ? this.aCompanies[0].id : null);
        this.bShowSpinner = false;
        this.get_categories(true, this.nCompanyId, bEdit);
      },
      oError => {
        this.bShowSpinner = false
        console.log(oError);
      }
    )
  }

  //Funcion que se va a encarar de cargar la imagen del producto.
  uploadImage(oEvent: any) {
    const sFile = oEvent.target.files[0];
    const oReader = new FileReader();
    oReader.readAsDataURL(sFile);
    oReader.onload = () => {
      this.oProductService.uploadImage(oReader.result, sFile.name).subscribe(
        oResult => {
          this.oProduct.image = oResult;
          this.bShowSpinner = false;
        },
        oError => {
          console.log(oError);
          this.bShowSpinner = false;
        }
      );
    };
  }

  get_categories(bShowSpinner, nCompanyId, bEdit?) {
    if (bShowSpinner) {
      this.bShowSpinner = true
    }
    this.oCategoryService.get_all_categories(nCompanyId).subscribe(
      oResult => {
        this.lCategories = oResult;
        if (!bEdit)
          this.oProduct.category_id = this.lCategories.length > 0 ? this.lCategories[0].id : null;
        this.bShowSpinner = false
      },
      oError => {
        this.bShowSpinner = false
      }
    )
  }

  fSelectChange(nCompanyId) {
    this.nCompanyId = nCompanyId;
    this.get_categories(true, nCompanyId);
  }
  /**
   * Metodo para obtener un producto en caso de que por url llegue un id
   * @param nProductId
   */
  get_product(nProductId) {
    this.bShowSpinner = true
    this.oProductService.get_product(nProductId).subscribe(
      oResult => {
        this.lGroups = oResult.groups
        this.oProduct = oResult
        this.oProduct.category_id = +oResult.category_id;
        this.oProduct.groups = this.lGroups
        this.bShowSpinner = false
      },
      oError => {
        this.bShowSpinner = false
      }
    )
  }

  /**
   * Agrgar grupo a la tabla
   */
  add_group() {
    if ('' != this.sGroupName) {
      let oGroup = new Group(null, this.sGroupName, null, [])
      this.lGroups.push(oGroup)
      this.sGroupName = ''
      this.bGroupError = false
    }
    else {
      this.bGroupError = true
    }
  }

  /**
   * Seleccionar un grupo
   * @param nIndex
   */
  select_group(nIndex) {
    this.nIndex = nIndex
  }

  /**
   * Agregar una opcion a un grupo
   */
  add_option() {
    if (this.sOptionName && this.nOptionPrice != undefined && this.nOptionPrice >= 0) {
      let oOption = new Option(null, this.sOptionName, null, this.nOptionPrice);
      this.lGroups[this.nIndex].options.push(oOption);
      this.sOptionName = '';
      this.nOptionPrice = 0;
      this.bOptionError = false;
    }
    else {
      this.bOptionError = true
    }
  }

  /**
   * Eliminar una opcion de un grupo
   * @param nIndex
   */
  remove_option(nIndex) {
    this.lGroups[this.nIndex].options.splice(nIndex, 1)
  }

  /**
   * Eliminar un grupo de un producto
   * @param nIndex
   */
  remove_group(nIndex) {
    this.lGroups.splice(nIndex, 1)
    this.nIndex = null
  }

  /**
   * Guardar producto
   */
  save_product() {
    this.bShowSpinner = true
    this.oProductService.save_product(this.oProduct).subscribe(
      oResult => {
        setTimeout(() => {
          this.bShowSpinner = false
          this.router.navigate(['/products']);
          this.open_alert(oResult.msg)
        }, 500);
      },
      oError => {
        this.open_alert(oError.body)
        this.bShowSpinner = false
      }
    )
  }

  /**
   * Mostrar notificacion dado un mensaje
   * @param sMsg
   */
  open_alert(sMsg) {
    this.oSnackBar.open(sMsg, '', {
      duration: 2000
    });
  }

}
