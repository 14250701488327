import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Company } from 'src/app/models/Company';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(
    private oHttpClient: HttpClient
  ) { }

  /**
  * Funcion encargada de consumir el servicio para obtener las empresas
  */
  get_companies() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.getToken()
      })
    };
    return this.oHttpClient.get<Company[]>(environment.sUrlAPI + 'companies', httpOptions).pipe(catchError(this.handleError))
  }

 /**
 * Devuelve el token en caso de tener un usuario logueado(almacenado en local storage)
 */
  private getToken() {
    if (localStorage.getItem("mafalda_web_session") && localStorage.getItem("mafalda_web_session") != '') {
      let Session = JSON.parse(localStorage.getItem("mafalda_web_session"));
      return Session.token;
    }
    return '';
  }

  /**
  * 
  */
  private handleError(error: HttpErrorResponse) {
    return throwError(
      {
        code: error.status,
        body: error.error
      }
    );
  }
}
