import { Component, OnInit } from '@angular/core';
import { ProductService } from 'src/app/services/product/product.service';
import { Product } from 'src/app/models/Product';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { CompanyService } from 'src/app/services/company/company.service';
import { Company } from 'src/app/models/Company';

@Component({
  selector: 'app-list-products',
  templateUrl: './list-products.component.html',
  styleUrls: ['./list-products.component.scss']
})
export class ListProductsComponent implements OnInit {

  lProducts: Product[] = [];
  lPaginationCount: number[] = [];
  nPaginationSelected: number = 1;
  nFrom: number = 0;
  nTo: number = 5;
  bShowSpinner: boolean = false;
  sSearchProducts: string = '';
  bResetPage: boolean = false;
  aCompanies: Company[] = [];
  nCompanyId: number = null;

  /**
   * Constructor
   * @param oProductService
   * @param router
   * @param oSnackBar
   */
  constructor(
    private oProductService: ProductService,
    private router: Router,
    private oSnackBar: MatSnackBar,
    private oCompanyServices: CompanyService
  ) { }

  /**
   * NgOnInit
   */
  ngOnInit() {
    this.get_companies(true);
  }

  /**
 * Funcion encargada de llamar al controlador de servicio para obtener las empresas(restaurantes)
 */
  get_companies(bShowSpinner) {
    if (bShowSpinner) {
      this.bShowSpinner = true
    }
    this.oCompanyServices.get_companies().subscribe(
      oResult => {
        this.aCompanies = oResult;
        this.nCompanyId = this.aCompanies.length > 0 ? this.aCompanies[0].id : null;
        this.bShowSpinner = false;
        this.get_products(true, this.nCompanyId);
      },
      oError => {
        this.bShowSpinner = false
        console.log(oError);
      }
    )
  }

  fSelectChange(nCompanyId) {
    this.nCompanyId = nCompanyId;
    this.get_products(true, nCompanyId);
  }

  /**
   * Funcion para obtener el listado de productos
   * @param bShowSpinner
   */
  get_products(bShowSpinner, nCompanyId) {
    if (bShowSpinner) {
      this.bShowSpinner = true
    }
    if (this.bResetPage) {
      this.pagination_move(1, false)
      this.bResetPage = false
    }
    this.oProductService.get_products(this.nPaginationSelected, this.sSearchProducts, nCompanyId).subscribe(
      oResult => {
        this.lProducts = oResult.products
        this.lPaginationCount = []
        for (let i = 1; i <= oResult.count; i++) {
          this.lPaginationCount.push(i);
        }
        this.bShowSpinner = false
      },
      oError => {
        this.bShowSpinner = false
      }
    )
  }

  /**
   * Fuuncion para buscar dentro del listado
   */
  search() {
    setTimeout(() => {
      if (!this.bResetPage) {
        this.bResetPage = true;
      }
      this.get_products(false, this.nCompanyId)
    }, 2000);
  }

  /**
   * Accion al boton del paginado para atras
   */
  move_back() {
    this.pagination_move(this.nPaginationSelected -= 1, true)
  }

  /**
   * Accion al boton del paginado para adelante
   */
  move_next() {
    this.pagination_move(this.nPaginationSelected += 1, true)
  }

  /**
   * Accion para cuando se clickea una pagina en especifico
   * @param nPage
   */
  move_to(nPage) {
    this.pagination_move(nPage, true)
  }

  /**
   * Funcion encaragada de navegar entre paginas
   * @param nPage
   * @param bSearchProducts
   */
  pagination_move(nPage, bSearchProducts) {
    this.nPaginationSelected = nPage
    switch (this.nPaginationSelected) {
      case 1:
      case 2:
        this.nFrom = 0
        this.nTo = 5
        break;
      case this.lPaginationCount.length - 1:
      case this.lPaginationCount.length:
        this.nFrom = this.lPaginationCount.length - 5 < 0 ? 0 : this.lPaginationCount.length - 5
        this.nTo = this.lPaginationCount.length
        break;
      default:
        this.nFrom = this.nPaginationSelected - 3 < 0 ? 0 : this.nPaginationSelected - 3
        this.nTo = this.nPaginationSelected + 2
        break;
    }
    if (bSearchProducts) {
      this.get_products(true, this.nCompanyId)
    }
  }

  /**
   * Funcion para reedirigir a editar un producto
   * @param nProductId
   */
  edit_product(nProductId) {
    this.router.navigate(['/products/', nProductId, this.nCompanyId]);
  }

  /**
   * Funcion para eliminar un producto
   * @param nProductId
   */
  delete_product(nProductId) {
    this.bShowSpinner = true
    this.oProductService.delete_product(nProductId).subscribe(
      oResult => {
        this.bShowSpinner = false
        this.get_products(true, this.nCompanyId)
        this.open_alert(oResult.msg)
      },
      oError => {
        this.bShowSpinner = false
      }
    )
  }

  /**
   * Mostrar notificacion dado un mensaje
   * @param sMsg
   */
  open_alert(sMsg) {
    this.oSnackBar.open(sMsg, '', {
      duration: 2000
    });
  }

}
