import { Component, OnInit, ViewChild } from '@angular/core';
import { Category } from 'src/app/models/Category';
import { CategoryService } from 'src/app/services/category/category.service';
import { Router } from '@angular/router';
import { ModalDirective } from 'angular-bootstrap-md';
import { CompanyService } from 'src/app/services/company/company.service';
import { Company } from 'src/app/models/Company';

@Component({
  selector: 'app-list-categories',
  templateUrl: './list-categories.component.html',
  styleUrls: ['./list-categories.component.scss']
})
export class ListCategoriesComponent implements OnInit {
  @ViewChild('categoryModal') categoryModal: ModalDirective;
  sSearchCategory: string = '';
  lCategory: Category[] = [];
  oCategory: Category = new Category(null, '', null, true);
  lPaginationCount: number[] = [];
  nPaginationSelected: number = 1;
  nFrom: number = 0;
  nTo: number = 5;
  bResetPage: boolean = false;
  bShowSpinner: boolean = false;
  sCategoryAction: string = '';
  aCompanies: Company[] = [];
  nCompanyId: number = null;
  bShowError: boolean = false;

  /**
   * constructor
   * @param oCategoryService
   * @param router
   */
  constructor(
    private oCategoryService: CategoryService,
    private router: Router,
    private oCompanyServices: CompanyService
  ) { }

  /**
   * NgOnInit
   */
  ngOnInit() {
    this.get_companies(true);
  }

  /**
   * Funcion encargada de llamar al controlador de servicio para obtener las empresas(restaurantes)
   */
  get_companies(bShowSpinner) {
    if (bShowSpinner) {
      this.bShowSpinner = true
    }
    this.oCompanyServices.get_companies().subscribe(
      oResult => {
        this.aCompanies = oResult;
        this.nCompanyId = this.aCompanies.length > 0 ? this.aCompanies[0].id : null;
        this.bShowSpinner = false;
        this.get_categories(true, this.nCompanyId);
      },
      oError => {
        this.bShowSpinner = false
        console.log(oError);
      }
    )
  }

  fSelectChange(nCompanyId) {
    this.nCompanyId = nCompanyId;
    this.get_categories(true, nCompanyId);
  }

  /**
   * Funcion encargada de devolver un listado de categorias
   * @param bShowSpinner
   */
  get_categories(bShowSpinner, nCompanyId) {
    if (bShowSpinner) {
      this.bShowSpinner = true
    }
    this.oCategoryService.get_categories(this.nPaginationSelected, this.sSearchCategory, nCompanyId).subscribe(
      oResult => {
        this.lCategory = oResult.categories
        this.lPaginationCount = []
        for (let i = 1; i <= oResult.count; i++) {
          this.lPaginationCount.push(i);
        }
        this.bShowSpinner = false
      },
      oError => {
        this.bShowSpinner = false
      }
    )
  }

  /**
   * Funcion para realizar una busqueda
   */
  search() {
    setTimeout(() => {
      if (!this.bResetPage) {
        this.bResetPage = true;
      }
      this.get_categories(false, this.nCompanyId)
    }, 2000);
  }

  /**
   * Accion al boton del paginado para ir atras
   */
  move_back() {
    this.pagination_move(this.nPaginationSelected -= 1, true)
  }

  /**
   * Accion al boton del paginado para ir adelante
   */
  move_next() {
    this.pagination_move(this.nPaginationSelected += 1, true)
  }

  /**
   * Accion para ir a una pagina en especifico
   * @param nPage
   */
  move_to(nPage) {
    this.pagination_move(nPage, true)
  }

  /**
   * Funcion encarga de navegar entre paginas
   * @param nPage
   * @param bSearchProducts
   */
  pagination_move(nPage, bSearchProducts) {
    this.nPaginationSelected = nPage
    switch (this.nPaginationSelected) {
      case 1:
      case 2:
        this.nFrom = 0
        this.nTo = 5
        break;
      case this.lPaginationCount.length - 1:
      case this.lPaginationCount.length:
        this.nFrom = this.lPaginationCount.length - 5 < 0 ? 0 : this.lPaginationCount.length - 5
        this.nTo = this.lPaginationCount.length
        break;
      default:
        this.nFrom = this.nPaginationSelected - 3 < 0 ? 0 : this.nPaginationSelected - 3
        this.nTo = this.nPaginationSelected + 2
        break;
    }
    if (bSearchProducts) {
      this.get_categories(true, this.nCompanyId);
    }
  }

  /**
   * Funcion encargada de abrir el modal para agregar una nueva categoria
   */
  open_modal_new() {
    this.bShowError = false;
    this.sCategoryAction = 'Nueva categoría'
    this.oCategory = new Category(null, '', this.nCompanyId, true)
    this.categoryModal.show()
  }

  /**
   * Funcino encargada de abrir el modal para editar
   * @param oCategory
   */
  open_modal_edit(oCategory) {
    this.sCategoryAction = 'Editar categoría'
    this.oCategory = new Category(oCategory.id, oCategory.name, this.nCompanyId, oCategory.is_active == "1" ? true : false)
    this.categoryModal.show()
  }

  /**
   * Funcion para agregar/editar una categoria
   */
  save_category() {
    if (this.oCategory && !this.oCategory.name) {
      this.bShowError = true;
    } else {
      this.oCategoryService.save_category(this.oCategory).subscribe(
        oResult => {
          this.categoryModal.hide()
          this.get_categories(true, this.nCompanyId);
        },
        oError => {
        }
      )
    }
  }

  /**
   * Funcion para eliminar una categoria
   * @param nCategoryId
   */
  delete_category(nCategoryId) {
    this.bShowSpinner = true
    this.oCategoryService.delete_category(nCategoryId).subscribe(
      oResult => {
        this.get_categories(true, this.nCompanyId)
        this.bShowSpinner = false
      },
      oError => {
        this.bShowSpinner = false
      }
    )
  }

}
