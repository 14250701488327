import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  sSessionName: string = '';
  bShowSpinner: boolean = false;
  constructor(
    private oUserService: UserService,
    public router: Router
  ) { }

  ngOnInit() {
    this.sSessionName = this.oUserService.get_logged_name()
  }

  logout(){
    this.bShowSpinner = true;
    this.oUserService.logout().subscribe(
      oSuccess => {

      },
      oError => {

      }
    );
    setTimeout(() => {
      this.bShowSpinner = false;
      localStorage.removeItem("mafalda_web_session");
      this.router.navigate(['login']);
    }, 2000);
  }
}
